import React, { useEffect } from 'react'
import { Select, Tooltip } from 'antd'
import './FormSelect.scss'
export const FormSelect = ({ ...props }) => {
  const { value, options, disabled, ...rest } = props
  // const [field, meta, helpers] = useField({ name })
  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  // const checkHaveData = !!options?.find(e => e.value == value)
  // const text = options?.find(e => e.value == value)?.label ?? ""
  const [text, setText] = React.useState('')
  const [width, setWidth] = React.useState(window.innerWidth)
  const [tagCount, setTagCount] = React.useState(2)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (width < 1500) {
      setTagCount(1)
    } else if (width < 1200) {
      setTagCount(0)
    } else {
      setTagCount(2)
    }
  }, [width])

  useEffect(() => {
    if (value && Array.isArray(value)) {
      const data = []
      value.forEach((e) => {
        const item = options?.find((i) => i.value == e)
        if (item) {
          data.push(item.label)
        }
      })
      setText(
        <ul>
          {data.map((e) => (
            <li key={e + Math.floor(Math.random() * 100000)}>{e}</li>
          ))}
        </ul>
      )
    } else {
      const item = options?.find((i) => i.value == value)
      setText(item?.label ?? '')
    }
  }, [value, options])

  return (
    <Tooltip placement='topLeft' title={text}>
      <Select
        size='large'
        dropdownStyle={{ maxHeight: 318 }}
        showSearch={options?.length}
        optionFilterProp='children'
        filterOption={filterOption}
        placeholder='Chọn'
        value={value}
        style={{ width: '100%', maxHeight: '40px' }}
        options={options}
        disabled={disabled}
        allowClear
        {...rest}
        maxTagCount={tagCount}
      />
    </Tooltip>
  )
}
