import { Button, Form, Radio } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_20 } from 'constants/constants'
import { ManageProgramVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Col, Label, Row, Table } from 'reactstrap'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { FormInputNumber } from 'components/Common/form-input-number/FormInputNumber'
import { formateDate, formatWithCommas } from 'helpers/global_helper'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormInputNumberCurrency } from 'components/Common/form-input-number/FormInputNumberCurrency'
import Spinners from 'components/Common/Spinner'
import TableContainer from 'components/Common/TableContainer'
import { getCustomerCareProductListAPI, getCustomerCareWarrantyListAPI } from 'helpers/backend_helper'
import { StatusProductActivation } from 'pages/ManageProductActivation/ManageProductActivationCol'

const WarrantyTab = ({ form, watchForm, industry, industrySector, category, model, type, programDetail }) => {
  const isEdit = type !== 'view'
  const numberOptions = Array.from(Array(9)).map((e, i) => ({ value: i + 1, label: i + 1 }))
  const [isLoading, setLoading] = useState(false)
  const [listTable, setListTable] = useState([])
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  const handleGetListTable = async (data) => {
    try {
      const res = await getCustomerCareWarrantyListAPI({ ...data, serial: data?.search, code: data?.search })

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    setPage(page)
  }

  const setGlobalFilterUser = async (data) => {
    handleGetListTable(data)
    setPage(1)
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: 'STT',
      //   accessor: 'stt',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return (page - 1) * 10 + cellProps.row.index + 1
      //   }
      // },
      {
        Header: 'Mã bảo hành',
        accessor: 'code',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Ngành',
        accessor: 'industrySector.parent.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySector.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Serial sản phẩm',
        accessor: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Name {...cellProps} />
        // }
      },
      {
        Header: 'Thời gian áp dụng',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.row.original
            ? formateDate(cellProps.row.original?.startApplyDate) +
                ' - ' +
                formateDate(cellProps.row.original?.endApplyDate)
            : '--'
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusProductActivation {...cellProps} />
        }
      }
    ],
    [filter, page]
  )

  // Content product type
  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Card>
          <CardBody>
            <TableContainer
              columns={columns}
              data={listTable}
              isGlobalFilter={true}
              setGlobalFilterUser={setGlobalFilterUser}
              isNoneGlobalFilter={true}
              isPagination={true}
              iscustomPageSizeOptions={false}
              isShowingPageLength={false}
              customPageSize={10}
              placeholderSearch={'Nhập từ khoá mã bảo hành'}
              tableClass='table-bordered align-middle nowrap mt-2'
              paginationDiv='col-sm-12 col-md-7'
              pagination='pagination justify-content-end pagination-rounded'
              useAPIChangePaging
              currentPage={page}
              gotoPageCustom={goToPage}
              total={total}
            />
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}
export default WarrantyTab
