import React, { useState } from 'react'
import { Card, Container, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

//redux
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Form, Spin } from 'antd'
import classNames from 'classnames'
import ProductTab from './components/ProductTab'
import WarrantyTab from './components/WarrantyTab'

const CustomerCare = (props) => {
  //meta title
  document.title = 'Customer care | HPG'
  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)


  const [activeTab, toggleTab] = useState('1')
  const [loading, setLoading] = useState(false)

  return (
    <React.Fragment>
      <div className='page-content'>
        <Spin spinning={loading}>
          <Container fluid>
            {/* Render Breadcrumb */}

            <h4 className=" mb-sm-0 font-size-18 pb-4">CHĂM SÓC KHÁCH HÀNG</h4>
            <Card>
              <ul className='nav nav-tabs nav-tabs-custom justify-content-center pt-2' role='tablist'>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classNames([
                      {
                        active: activeTab === '1'
                      }
                    ])}
                    onClick={() => {
                      toggleTab('1')
                    }}
                  >
                    {'Sản phẩm'}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classNames([
                      {
                        active: activeTab === '2'
                      }
                    ])}
                    onClick={() => {
                      toggleTab('2')
                    }}
                  >
                    {'Mã bảo hành'}
                  </NavLink>
                </NavItem>
              </ul>
              <Form>
                <TabContent className='p-4' activeTab={activeTab}>
                  <TabPane tabId='1'>
                    <ProductTab
                      watchForm={watchForm}
                      form={form}
                      setLoading={setLoading}
                    />
                  </TabPane>
                  <TabPane tabId='2'>
                    <WarrantyTab
                      watchForm={watchForm}
                      form={form}
                    />
                  </TabPane>
                </TabContent>
              </Form>
            </Card>
          </Container>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CustomerCare)
