import React, { useEffect, useState } from 'react'
import { Col } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { AREA_TYPE } from 'pages/ManageLocality/constants'
import { getZoneAPI, getAreaManagementOptionsAPI } from 'helpers/backend_helper'

const NoneGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})

  return (
    <React.Fragment>
      <Col xxl={4} lg={4} className='d-flex align-items-end justify-content-start mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined,
                abbreviation: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default NoneGlobalFilter
