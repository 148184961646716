import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { CommonVN, PointHistoryVN, ProductVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { toast } from 'react-toastify'
import { getPortfolioAllAPI } from 'helpers/backend_helper'
import { MAX_LENGTH_SEARCH, SumProductOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormInput } from './form-input/FormInput'
import { FormDateRange } from './form-date-range/FormDateRange'
import dayjs from 'dayjs'

const PointHistoryGlobalFilter = ({ setGlobalFilter, search, setSearch, optionPermissionGroup }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({
    start_date: dayjs().month(dayjs().get('month') - 2).startOf('month').startOf('day'),
    end_date: dayjs().endOf('day')
  });

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [model, setModel] = useState()
  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const getIndustryDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setIndustry(resIndustry?.data)
      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }

      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>{PointHistoryVN.filters.accountType.title}</Label>
        <FormSelect
          options={[
            { value: 'user', label: 'Cửa hàng' },
            { value: 'technician', label: 'Kỹ thuật viên' }
          ]}
          value={filter.client}
          onChange={(value) => {
            handleSelect(value, 'client')
          }}
          placeholder={PointHistoryVN.filters.accountType.placeholder}
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Số điện thoại'
          value={filter?.phone || ''}
          name='phone'
          id={`phone`}
          onChange={(value) => {
            handleSelect(value.target.value, 'phone')
          }}
          onBlur={(value) => {
            handleSelect(value.target.value, 'phone')
          }}
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter?.zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'zone_ids')
            handleSelect(label.map((e) => e.label, 'zone_names'))

            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'sub_zone_names')
            handleSelect([], 'province_names')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'zone_ids')
              handleSelect([...all_names], 'zone_names')
            }
          }}
          placeholder={ProductVN.filter?.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={
            lstSubZone?.filter((e) => filter?.zone_ids?.includes(e.zoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstSubZone
                    ?.filter((e) => filter?.zone_ids?.includes(e.zoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.sub_zone_ids}
          onChange={(value, label) => {
            handleSelect(value, 'sub_zone_ids')
            handleSelect(label.map((e) => e.label, 'sub_zone_names'))

            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'province_names')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstSubZone?.filter((e) => filter?.zone_ids?.includes(e.zoneId))?.map((item) => item.id)
            const all_names = lstSubZone?.filter((e) => filter?.zone_ids?.includes(e.zoneId))?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'sub_zone_ids')
              handleSelect([...all_names], 'sub_zone_names')
            }
          }}
          placeholder={ProductVN.filter?.subZone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={
            lstProvionce?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstProvionce
                    ?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.province_ids}
          onChange={(value, label) => {
            handleSelect(value, 'province_ids')
            handleSelect(label.map((e) => e.label, 'province_names'))

            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstProvionce?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))?.map((item) => item.id)
            const all_names = lstProvionce
              ?.filter((e) => filter?.sub_zone_ids?.includes(e.subZoneId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'province_ids')
              handleSelect([...all_names], 'province_names')
            }
          }}
          placeholder='Chọn tỉnh/ thành phố'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>{'Quận/ Huyện'}</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter?.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter?.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.district_ids}
          onChange={(value, label) => {
            handleSelect(value, 'district_ids')
            handleSelect(
              label.map((e) => e.label),
              'district_names'
            )
            handleSelect([], 'ward_ids')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter?.province_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstDistrict
              ?.filter((e) => filter?.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'district_ids')
              handleSelect([...all_names], 'district_names')
            }
          }}
          placeholder='Chọn quận/ huyện'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>{'Phường/ xã'}</Label>
        <FormSelect
          options={
            lstWard?.filter((e) => filter?.district_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstWard
                    ?.filter((e) => filter?.district_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter?.ward_ids}
          onChange={(value, label) => {
            handleSelect(value, 'ward_ids')
            handleSelect(
              label.map((e) => e.label),
              'ward_names'
            )
          }}
          onSelect={(value) => {
            const all = lstWard?.filter((e) => filter?.district_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstWard
              ?.filter((e) => filter?.district_ids?.includes(e.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'ward_ids')
              handleSelect([...all_names], 'ward_names')
            }
          }}
          placeholder='Chọn phường/ xã'
          mode='multiple'
        />
      </Col>

      {/* <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{PointHistoryVN.filters.industry.title}</Label>
        <FormSelect
          options={optionsIndustry}
          value={filter.industry_sector_ids}
          onChange={(value) => {
            handleSelect(value, 'industry_sector_ids')
            handleSelect(undefined, 'category_ids')
            handleGetOptionsProducts(value)
          }}
          placeholder={PointHistoryVN.filters.industry.placeholder}
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Nhóm sản phẩm</Label>
        <FormSelect
          options={
            optionsProducts?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  optionsProducts
                    ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            handleSelect('category_ids', value)
          }}
          onSelect={(value) => {
            const all = category
              ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.id)

            if (value && value === 'all') {
              onFilterChange('category_ids', [...all])
            }
          }}
          value={filter.category_ids}
          placeholder='Chọn nhóm sản phẩm'
          mode='multiple'
        />
      </Col> */}

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={
            industrySector?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector?.map((item) => ({
                    value: item.id,
                    label: item.name
                  }))
                )
              : []
          }
          onChange={(value, label) => {
            handleSelect(value, 'industry_sector_ids')

            handleSelect([], 'category_ids')
            handleSelect([], 'model_ids')
          }}
          onSelect={(value) => {
            const all = industrySector?.filter((e) => filter.industry_ids?.includes(e.parentId))?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'industry_sector_ids')
            }
          }}
          value={filter.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Nhóm sản phẩm</Label>
        <FormSelect
          options={
            category?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  category
                    ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            handleSelect(value, 'category_ids')

            handleSelect([], 'model_ids')
          }}
          onSelect={(value) => {
            const all = category
              ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.id)

            if (value && value === 'all') {
              handleSelect([...all], 'category_ids')
            }
          }}
          value={filter.category_ids}
          placeholder='Chọn nhóm sản phẩm'
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Tính tổng</Label>
        <FormSelect
          // listHeight={450}
          placeholder='Tính tổng'
          style={{ width: '100%' }}
          options={SumProductOptions}
          onChange={(value, label) => {
            handleSelect(value, 'group_by')
            handleSelect(label.label, 'group_by_name')
          }}
          value={filter.group_by}
        />
      </Col>
      <Col xxl={3} lg={2} className='mt-3'>
        <Label className='form-label'>Thời gian</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              onFilterChange('start_date', value[0])
              onFilterChange('end_date', value[1])
            } else {
              onFilterChange('start_date', undefined)
              onFilterChange('end_date', undefined)
            }
          }}
        />
      </Col>

      <Col xxl={3} lg={2} className='d-flex align-items justify-content mt-5'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined,
                group_by: undefined,
                group_by_name: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search, start_date: filter?.start_date?.startOf('day'), end_date: filter?.end_date?.endOf('day')  })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default PointHistoryGlobalFilter
