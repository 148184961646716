import axios from 'axios'
import { del, fetcherApi, get, patch, post, put } from './api_helper'
import { COOKIE_KEYS_TOKEN } from 'constants/constants'
import * as url from './url_helper'
import { parseCookies } from './global_helper'
import requestHandler, { STATUS } from './request-handler'

// upload image
const postImage = (data) =>
  axios.post(`${process.env.REACT_APP_API_URL}${url.POST_IMAGE}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

// upload file
const importFileAPI = (url, data) => {
  const token = parseCookies(null)[COOKIE_KEYS_TOKEN]
  return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token || ''
    }
  })
}

const checkUploadFileAPI = (data) => get(url.CHECK_UPLOAD, { params: data })

const exportFileError = (data) => get(url.EXPORT_FILE_ERROR, { params: data, responseType: 'blob' })

const checkExportFileAPI = (data) => get(url.CHECK_EXPORT, { params: data })

// dowload template
const downloadTemplateAPI = (data) => get(url.GET_TEMPLATE, { params: data, responseType: 'blob' })

// Login Method
const postJwtLogin = (data) => post(url.POST_JWT_LOGIN, data)

const postPreLogin = (data) => post(url.POST_PRE_LOGIN, data)

// Logout Method
const postJwtLogout = (data) => post(url.POST_JWT_LOGOUT, data)

// Refresh token
export const refreshToken = async (refreshToken) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${url.POST_REFRESH}`,
    { refresh_token: refreshToken },
    {
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_APIKEY}`
      }
    }
  )
}

// ChangePassword Method
const putChangePassword = (data) => put(url.PUT_CHANGE_PASSWORD, data)

// get profile Method
const getProfileAPI = () => get(url.GET_PROFILE)

// put profile Method
const putProfileAPI = (data) => put(url.PUT_PROFILE, data)

// update profile Method
const updateProfileAPI = (data) => put(url.UPDATE_PROFILE, data)

// get list shop Method --sửa truyền nhiều
// const getShopsAPI = (data) => get(url.GET_SHOPS, { params: { client: 'user', ...data } })
const getShopsAPI = (data) =>
  post(
    url.GET_SHOPS,
    {
      client: 'user',
      ...data,
      meta: {
        'Nhập tên': data?.search,
        'Nhập số điện thoại': data?.phone,
        'Tỉnh/ Thành phố': data?.province_names,
        'Quận/ Huyện': data?.district_names,
        'Phường xã': data?.ward_names,
        'Trạng thái hoạt động': data?.status_name,
        'Trạng thái tài khoản': data?.member_name
      }
    },
    { params: { page: data?.page || 1 } }
  )

// get list KTV Method  --sửa truyền nhiều
const getKTVsAPI = (data) =>
  post(
    url.GET_SHOPS_TECHNICIAN,
    {
      client: 'technician',
      ...data,
      meta: {
        'Nhập tên': data?.search,
        'Nhập số điện thoại': data?.phone,
        'Tỉnh/ Thành phố': data?.province_names,
        'Quận/ Huyện': data?.district_names,
        'Phường xã': data?.ward_names,
        'Trạng thái hoạt động': data?.status_name
      }
    },
    { params: { page: data?.page || 1 } }
  )

// get detail shop Method
const getShopDetailAPI = (data) => get(url.GET_SHOP_DETAIL, { params: data })

// put update detail shop Method
const putShopDetailAPI = (data) => put(url.GET_SHOP_DETAIL + `/${data?.id}`, data)

// put request detail shop Method
const putRequestAPI = (data) => put(url.GET_SHOP_DETAIL, data)

// get province Method
const getProvinceAPI = (data) => get(url.GET_PROVINCE, data)

// get district Method
const getDistrictAPI = (data) => get(url.GET_DISTRICT, data)

// get district Method
const getBanksAPI = () => get(url.GET_BANKS)

// get ward Method
const getWardAPI = (data) => get(url.GET_WARD, data)

// get zone Method
const getZoneAPI = (data) => get(url.GET_ZONE, data)

// get subzone Method
const getSubZoneAPI = (data) => get(url.GET_SUBZONE, data)

// get reset password user Method
const resetPasswordAPI = (data) => put(url.POST_RESET_PASSWORD, data)

// get reset password technician Method
const resetTechnicianPasswordAPI = (data) => put(url.POST_TECHNICIAN_RESET_PASSWORD, data)

// switch technician to user Method
const switchTechnicianToUserAPI = (data) => patch(url.SWITCH_TECHNICIAN_TO_USER + `/${data?.id}`, data)

// switch user to technician Method
const switchUserToTechnicianAPI = (data) => patch(url.SWITCH_USER_TO_TECHNICIAN + `/${data?.id}`, data)

// lock account Method
const lockAccountAPI = (data) =>
  patch(url.POST_LOCK_ACCOUNT + `/${data?.id}`, {
    status: data.status,
    note: data.note
  })

// lock account Method for shop
const lockShopAccountAPI = (data) =>
  patch(url.POST_SHOP_LOCK_ACCOUNT + `/${data?.id}`, {
    status: data.status,
    note: data.note
  })

// lock account Method for technician
const lockTechnicianAccountAPI = (data) =>
  patch(url.POST_TECHNICIAN_LOCK_ACCOUNT + `/${data?.id}`, {
    status: data.status,
    note: data.note
  })

// patch accept member
const acceptMemberAPI = (data) => patch(url.PATCH_ACCEPT_MEMBER, data)

// get file export
const exportListAPI = (data, config) => post(url.GET_EXPORT_LIST, data, config)

const exportListFetcherAPI = (data, config) => fetcherApi.post(url.GET_EXPORT_LIST, data, config)
//get file exxport technician
const exportTechnicianListAPI = (data) => post(url.GET_EXPORT_TECHNICIAN_LIST, data)
const exportTechnicianListFetcherAPI = (data) => fetcherApi.post(url.GET_EXPORT_TECHNICIAN_LIST, data)

// get user rank
const getUserRankAPI = (data) => get(url.GET_USER_RANK, { params: data })

const getUserHistoryChangeApi = (data) => get(url.GET_USER_HISTORY_CHANGE, { params: data })

// portfolio
const getPortfolioAllAPI = (data) => get(url.GET_PORTFOLIO_ALL, { params: data })

const getPortfolioListAPI = (data) => get(url.GET_PORTFOLIO_LIST, { params: { ...data } })

const postPortfolioAPI = (data) => post(url.POST_PORTFOLIO_CREATE, data)

const getPortfolioDetailsAPI = (data) => get(url.GET_PORTFOLIO_DETAILS, { params: data })

const updatePortfolioAPI = (data) => put(url.PUT_PORTFOLIO + data.id, data)

const deletePortfolioAPI = (data) => del(url.DELETE_PORTFOLIO + data.id, { data: { type: data.type } })

const exportPortfolioAPI = (data) => get(url.EXPORT_PORTFOLIO, { params: data, responseType: 'blob' })

// product
const getProductAllAPI = (data) => get(url.GET_PRODUCT_ALL, { params: data })

const getProductListAPI = (data) => post(url.GET_PRODUCT_LIST, data, { params: { page: data?.page || 1 } })

const postProductAPI = (data) => post(url.POST_PRODUCT_CREATE, data)

const checkSerialAPI = (data) => post(url.CHECK_SERIAL_PRODUCT, data)

const getProductDetailsAPI = (data) => get(url.GET_PRODUCT_DETAILS, { params: data })

const updateProductAPI = (data) => put(url.PUT_PRODUCT + data.id, data)

const deleteProductAPI = (data) => del(url.DELETE_PRODUCT + data.id)

const exportProductAPI = (data) => post(url.EXPORT_PRODUCT, data)
const exportProductFetcherAPI = (data) => fetcherApi.post(url.EXPORT_PRODUCT, data)

// product activation
const getProductActivationListAPI = (data) =>
  post(url.GET_PRODUCT_ACTIVATION_LIST, data, { params: { page: data?.page || 1 } })

const postProductActivationAPI = (data) => post(url.POST_PRODUCT_ACTIVATION_CREATE, data)

const getProductActivationDetailsAPI = (data) => get(url.GET_PRODUCT_ACTIVATION_DETAILS, { params: data })

const updateProductActivationAPI = (data) => put(url.PUT_PRODUCT_ACTIVATION + data.id, data)

const deleteProductActivationAPI = (data) => del(url.DELETE_PRODUCT_ACTIVATION + data.id, { data: { type: data.type } })

const patchLockProductActivationAPI = (data) => patch(url.PATCH_PRODUCT_ACTIVATION + data.id, { status: data.status })

const exportProductActivationAPI = (data) => post(url.EXPORT_PRODUCT_ACTIVATION, data)
const exportProductActivationFetcherAPI = (data) => fetcherApi.post(url.EXPORT_PRODUCT_ACTIVATION, data)

// unit
const getUnitListAPI = (data) => get(url.GET_UNIT_LIST, { params: { ...data } })

const postUnitAPI = (data) => post(url.POST_UNIT_CREATE, data)

const getUnitDetailsAPI = (data) => get(url.GET_UNIT_DETAILS, { params: data })

const postCreateUnitAPI = (data) => post(url.POST_UNIT_CREATE, data)

const updateUnitAPI = (data) => put(url.PUT_UNIT + data.id, data)

const deleteUnitAPI = (data) => del(url.DELETE_UNIT + data.id, { data: { type: data.type } })

const getDepartmentListAPI = (data) => get(url.GET_DEPARTMENT_LIST, { params: { ...data } })

// Permission
const getPermissionListAPI = (data) => get(url.GET_PERMISSION_LIST, { params: { ...data } })

const postPermissionAPI = (data) => post(url.POST_PERMISSION_CREATE, data)

const getPermissionDetailsAPI = (data) => get(url.GET_PERMISSION_DETAILS, { params: data })

const postCreatePermissionAPI = (data) => post(url.POST_PERMISSION_CREATE, data)

const updatePermissionAPI = (data) => put(url.PUT_PERMISSION + data.id, data)

const deletePermissionAPI = (data) => del(url.DELETE_PERMISSION + data.id, { data: { type: data.type } })

const getRoleListAPI = (data) => get(url.GET_ROLE_LIST, { params: { ...data } })

// User
const getUserListAPI = (data) => get(url.GET_USER_LIST, { params: { ...data } })

const postUserAPI = (data) => post(url.POST_USER_CREATE, data)

const getUserDetailsAPI = (data) => get(url.GET_USER_DETAILS, { params: data })

const postCreateUserAPI = (data) => post(url.POST_USER_CREATE, data)

const updateUserAPI = (data) => put(url.PUT_USER + data.id, data)

const getRoleListAll = () => get(url.GET_ROLE_LIST_ALL)

const getZoneListAll = () => get(url.GET_ZONE_LIST_ALL)

const getSubZoneListAll = () => get(url.GET_SUB_ZONE_LIST_ALL)

const getProvinceListAll = () => get(url.GET_PROVINCE_LIST_ALL)

const getWardListAll = () => get(url.GET_WARD)

const getDistrictListAll = () => get(url.GET_DISTRICT)

const checkRoleManagement = (data) => post(url.CHECK_ROLE_MANAGEMENT, data)

const postLockAccountAPI = (data) => patch(url.POST_LOCK_ACCOUNT_ADMIN + data.id, data)

const putChangePasswordAccountAPI = (data) => put(url.POST_CHANGE_PASSWORD_ACCOUNT_ADMIN, data)

const myLocationManagementAPI = () => get(url.MY_LOCATION_MANAGEMENT)

// Locality
const getLocalityListAPI = (data) => post(url.GET_LOCALITY_LIST, data, { params: { page: data?.page || 1 } })

const postLocalityAPI = (data) => post(url.POST_LOCALITY_CREATE, data)

const getLocalityDetailsAPI = (data) => get(url.GET_LOCALITY_DETAILS, { params: data })

const postCreateLocalityAPI = (data) => post(url.POST_LOCALITY_CREATE, data)

const updateLocalityAPI = (data) => put(url.PUT_LOCALITY + data.id, data)

const deleteLocalityAPI = (data) => del(url.DELETE_LOCALITY + data.id, { data: { type: data.type } })

const getAreaManagementOptionsAPI = (data) => get(url.GET_AREA_MANAGEMENT_LIST, { params: { ...data } })

// Rank
const getRankListAPI = (data) => get(url.GET_RANK_LIST, { params: { ...data } })

const getRankDetailsAPI = (data) => get(url.GET_RANK_DETAILS, { params: data })

const postCreateRankAPI = (data) => post(url.POST_RANK_CREATE, data)

const updateRankAPI = (data) => put(url.PUT_RANK + data.id, data)

const deleteRankAPI = (data) => del(url.DELETE_RANK + data.id)

// Rank Config
const getRankConfigListAPI = (data) => post(url.GET_RANK_CONFIG_LIST, data, { params: { page: data?.page || 1 } })

const getRankConfigDetailsAPI = (data) => get(url.GET_RANK_CONFIG_DETAILS, { params: data })

const postCreateRankConfigAPI = (data) => post(url.POST_RANK_CONFIG_CREATE, data)

const updateRankConfigAPI = (data) => put(url.PUT_RANK_CONFIG + data.id, data)

const deleteRankItemConfigAPI = (data) => del(url.DELETE_RANK_ITEM_CONFIG + data.id)

const deleteRankConfigAPI = (data) => del(url.DELETE_RANK_CONFIG + data.id)

// Point Config
const getPointConfigListAPI = (data) => post(url.GET_POINT_CONFIG_LIST, data, { params: { page: data?.page || 1 } })

const updatePointConfigAPI = (data) => put(url.PUT_POINT_CONFIG, data)

// Point Config by time
const getPointConfigByTimeListAPI = (data) =>
  post(url.GET_POINT_CONFIG_BY_TIME_LIST, data, { params: { page: data?.page || 1 } })

const postPointConfigByTimeAPI = (data) => post(url.CREATE_POINT_CONFIG_BY_TIME, data)

const updatePointConfigByTimeAPI = (data) => put(url.PUT_POINT_CONFIG_BY_TIME + data?.id, data)

const getPointConfigByTimeDetailAPI = (data) => get(url.GET_POINT_CONFIG_BY_TIME_DETAILS + data?.id, { params: data })

const deletePointConfigByTimeAPI = (data) => del(url.DELETE_POINT_CONFIG_BY_TIME + data.id)

// Point History
const getPointHistoryListAPI = (data) => post(url.GET_POINT_HISTORY_LIST, data, { params: { page: data?.page || 1 } })

const getChannelAPI = (data) => get(url.GET_CHANNEL, { params: data })

const exportPointHistoryListFetcherAPI = (data, config) => fetcherApi.post(url.EXPORT_POINT_HISTORY_LIST, data, config)

// Program
const getProgramListAPI = (data) => get(url.GET_PROGRAM_LIST, { params: { ...data } })

const postProgramAPI = (data) => post(url.POST_PROGRAM_CREATE, data)

const getProgramDetailsAPI = (data) => get(url.GET_PROGRAM_DETAILS + data)

const updateProgramAPI = (data) => put(url.PUT_PROGRAM + data.id, data)

const deleteProgramAPI = (data) => del(url.DELETE_PROGRAM + data.id)

const patchStatusProgramAPI = (data) =>
  patch(url.PUT_PROGRAM + data.id + '/status', { status: data?.status, noteFromAdmin: data?.note })

const getRankAllAPI = (data) => get(url.GET_RANK_ALL, { params: { ...data } })

const getParallelProgramListAPI = (data) => get(url.GET_PARALLEL_PROGRAM_LIST, { params: { ...data } })

const getAccountRankListAPI = (data) => post(url.GET_ACCOUNT_RANK_LIST, { ...data })

// Accumulation
const getAccumulationListAPI = (data) =>
  post(url.GET_ACCUMULATION_LIST, { type: 'accumulation', ...data }, { params: { page: data?.page || 1 } })

const getAccumulationDetailsAPI = (data) =>
  get(url.GET_ACCUMULATION_DETAILS + data.product_id, { params: { user_id: data.user_id, type: 'accumulation' } })

const updateAccumulationAPI = (data) =>
  patch(url.PUT_ACCUMULATION + data.id, { model_id: data?.modelId, user_id: data?.userId, type: 'accumulation' })

const deleteAccumulationAPI = (data) => del(url.DELETE_ACCUMULATION + data.id)

const exportAccumulationAPI = (data) => post(url.EXPORT_ACCUMULATION, { ...data, type: 'accumulation' })
const exportAccumulationFetcherAPI = (data) =>
  fetcherApi.post(url.EXPORT_ACCUMULATION, { ...data, type: 'accumulation' })

const getModelBySerialAPI = (data) => get(url.GET_MODEL_BY_SERIAL + data)

// Activation
const getActivationListAPI = (data) =>
  post(url.GET_ACTIVATION_LIST, { type: 'activation', ...data }, { params: { page: data?.page || 1 } })

const getActivationListReportAPI = (data) =>
  post(url.GET_DETAIL_ACTIVATION_REPORT_LIST + data.programming_id, { type: 'activation', ...data }, { params: { page: data?.page || 1 } })

const getActivationDetailsAPI = (data) =>
  get(url.GET_ACTIVATION_DETAILS + data.product_id, { params: { user_id: data.user_id, type: 'activation' } })

const updateActivationAPI = (data) =>
  patch(url.PUT_ACTIVATION + data.id, { model_id: data?.modelId, user_id: data?.userId, type: 'activation' })

const deleteActivationAPI = (data) => del(url.DELETE_ACTIVATION + data.id)

const exportActivationAPI = (data) => post(url.EXPORT_ACTIVATION, { ...data, type: 'activation' })
const exportActivationFetcherAPI = (data) => fetcherApi.post(url.EXPORT_ACTIVATION, { ...data, type: 'activation' })

// Rank
const getGuestListAPI = (data) => post(url.GET_GUEST_LIST, data, { params: { page: data?.page || 1 } })

const getGuestDetailsAPI = (data) => get(url.GET_GUEST_DETAILS, { params: data })

const updateGuestAPI = (data) => put(url.PUT_GUEST + data.id, data)

const deleteGuestAPI = (data) => del(url.DELETE_GUEST + data.id)

const exportGuestAPI = (data) => post(url.EXPORT_GUEST, data, { responseType: 'blob' })

// Content
const getContentsAPI = (data) => post(url.GET_CONTENT_LIST, data, { params: { page: data?.page || 1 } })

const getContentDetailAPI = (data) => get(url.GET_CONTENT_DETAILS + data?.id)

const putContentDetailAPI = (data) => put(url.PUT_CONTENT + data?.id, data)

const postContentAPI = (data) => post(url.POST_CONTENT, data)

const deleteContentAPI = (data) => del(url.DELETE_CONTENT + data.id)

const patchStatusContentAPI = (data) =>
  patch(url.PATCH_STATUS_CONTENT + data.id, { status: data?.status, node: data?.node })

const getContentAllAPI = (data) => get(url.GET_CONTENT_ALL, { params: data })

// Baner
const getBanerAllAPI = (data) => get(url.GET_BANER_ALL, { params: data })

const getBanerListAPI = (data) => get(url.GET_BANER_LIST, { params: { ...data } })

const postBanerAPI = (data) => post(url.POST_BANER_CREATE, data)

const getBanerDetailsAPI = (data) => get(url.GET_BANER_DETAILS, { params: data })

const updateBanerAPI = (data) => put(url.PUT_BANER, data)

const createBannerAPI = (data) => post(url.POST_BANER_CREATE, data)

const deleteBanerAPI = (data) => del(url.DELETE_BANER + data.id)

// Notification
const getNotificationsAPI = (data) => get(url.GET_NOTIFICATION_LIST, { params: { ...data } })
const getNotificationDetailAPI = (data) => get(url.GET_NOTIFICATION_DETAILS + data?.id)
const postNotificationAPI = (data) => post(url.POST_NOTIFICATION, data)
const updateNotificationAPI = (data) => patch(url.PATCH_NOTIFICATION + data?.id, data)
const getUserAccountsAPI = (data) => post(url.GET_USER_ACCOUNT, data)
const cancleNotificationAPI = (data) => del(url.CANCEL_NOTIFICATION + data.id)

// Report đối soát
const getProgramBonusTempListAPI = (data) => get(url.GET_PROGRAM_BONUS_TEMP_LIST, { params: { ...data } })
const getProductBonusTempListAPI = (data) =>
  post(url.GET_PRODUCT_BONUS_TEMP_LIST, data, { params: { page: data?.page || 1 } })
const exportByProductAPI = (data) => post(url.EXPORT_BY_PRODUCT, data)
const exportByProductFetcherAPI = (data) => fetcherApi.post(url.EXPORT_BY_PRODUCT, data)
const getReconcileProgramByAreaAPI = (data) =>
  post(url.GET_RECONCILE_PROGRAM_BY_AREA, data, { params: { page: data?.page || 1 } })
const getProgramByAreaAndRewardTypeAPI = (data) => get(url.GET_PROGRAM_BY_AREA_AND_REWARD_TYPE, { params: { ...data } })
const confirmReconcileByProductAPI = (data) =>
  put(url.CONFIRM_RECONCILE_BY_PRODUCT + data.id + '/province/' + data.province_id, data)
const confirmReconcileByPackageAPI = (data) =>
  put(url.CONFIRM_RECONCILE_BY_PACKAGE + data.id + '/province/' + data.province_id, data)
const exportByProgrammingAPI = (data) => get(url.EXPORT_BY_PROGRAMMING, { params: data })
const exportByProgrammingFetcherAPI = (data) => fetcherApi.post(url.EXPORT_BY_PROGRAMMING, { params: data })
const exportProgrammingByAreaAndRewardTypeAPI = (data) =>
  get(url.EXPORT_PROGRAMMING_BY_AREA_AND_REWARD_TYPE, { params: data })
const setPriorityReconcileProductAPI = (data) => put(url.SET_PRIORITY_RECONCILE_PRODUCT, data)

// dowload template report
const downloadTemplateReportAPI = (data) => get(url.GET_REPORT_TEMPLATE, { params: data, responseType: 'blob' })

// dowload template log kế toán
const downloadTemplateReconcileAccountingAPI = (data) =>
  get(url.GET_TEMPLATE_RECONCILE_ACCOUNTING, { params: data, responseType: 'blob' })

// Report log kế toán
const getReportAccountingSearchByProgramingAPI = (data) =>
  get(url.GET_REPORT_ACCOUNTING_SEARCH_BY_PROGRAMING, { params: { ...data } })
const getReportAccountingSearchProgramingByRewardTypeAPI = (data) =>
  get(url.GET_REPORT_ACCOUNTING_SEARCH_PROGRAMING_BY_REWARD_TYPE, { params: { ...data } })
const confirmAccountingReconcileByProductAPI = (data) => put(url.CONFIRM_ACCOUNT_RECONCILE_BY_PRODUCT + data.id, data)
const confirmAccountingReconcileByPackageAPI = (data) => put(url.CONFIRM_ACCOUNT_RECONCILE_BY_PACKAGE + data.id, data)
const exportReportAccountingProgrammingByRewardTypeAPI = (data) =>
  get(url.EXPORT_REPORT_ACCOUNTING_PROGRAMMING_BY_REWARD_TYPE, { params: data })

//Contacts
const getContactListAPI = (data) => get(url.GET_CONTACT, { params: { ...data } })
const getContactDetailAPI = (data) => get(url.GET_CONTACT_DETAIL + data.id, { params: data })
const createContactAPI = (data) => post(url.POST_CONTACT, data)
const updateContactAPI = (data) => patch(url.PATCH_CONTACT + data.id, data)
const deleteContactAPI = (data) => del(url.DELETE_CONTACT + data.id, { data: { type: data.type } })

//SMS Brandname
const getSMSBrandnameListAPI = (data) => get(url.GET_SMS_STATISTICS, { params: { ...data } })
const exportSMSBrandnameAPI = (data) => get(url.EXPORT_SMS_STATISTICS, { params: data, responseType: 'blob' })

//user statistic
const getReportUserStatisticAPI = (data) =>
  post(url.GET_REPORT_USER_STATISTIC, data, { params: { page: data?.page || 1 } })
const exportReportUserStatisticAPI = (data) => post(url.EXPORT_REPORT_USER_STATISTIC, data, { responseType: 'blob' })

//account rank
const getReportAccountRankAPI = (data) => post(url.GET_REPORT_ACCOUNT_RANK, data, { params: { page: data?.page || 1 } })
const exportReportAccountRankAPI = (data) => post(url.EXPORT_REPORT_ACCOUNT_RANK, data, { responseType: 'blob' })

//Import History
const getImportHistoryListAPI = (data) => get(url.GET_IMPORT_HISTORY, { params: { ...data } })
const getImportHistoryDetailAPI = (data) => get(url.GET_IMPORT_HISTORY_DETAIL, { params: { ...data } })
const getImportHistoryStatusAPI = (data) => get(url.GET_IMPORT_HISTORY_STATUS, { params: { ...data } })

//Export History
const getExportHistoryListAPI = (data) => get(url.GET_EXPORT_HISTORY, { params: { ...data } })

//Rank History
const getRankHistoryListAPI = (data) => post(url.GET_RANK_HISTORY, data, { params: { page: data?.page || 1 } })

//Audit Log
const getAuditLogAPI = (data) => post(url.GET_AUDIT_LOG, data, { params: { page: data?.page || 1 } })
const getAuditLogDetailAPI = (data) => get(url.GET_AUDIT_LOG_DETAIL + data.id, { params: data })

//Customer Care
const getCustomerCareProductListAPI = (data) =>
  post(url.GET_CUSTOMER_CARE_PRODUCT_LIST, data, { params: { page: data?.page || 1 } })
const getCustomerCareWarrantyListAPI = (data) =>
  post(url.GET_CUSTOMER_CARE_WARRANTY_LIST, data, { params: { page: data?.page || 1 } })

/** api fake BE  */
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem('user')
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = (data) => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch((err) => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found'
            break
          case 500:
            message = 'Sorry! something went wrong, please contact our support team'
            break
          case 401:
            message = 'Invalid credentials'
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = (data) => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = (data) => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = (data) => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = (data) => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch((err) => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = 'Sorry! the page you are looking for could not be found'
            break
          case 500:
            message = 'Sorry! something went wrong, please contact our support team'
            break
          case 401:
            message = 'Invalid credentials'
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// postForgetPwd
const postJwtForgetPwd = (data) => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = (data) => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = (id) => get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = (event) => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = (event) => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = (event) => del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = '') => get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails)

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) =>
  post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab })

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = (order) => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = (order) => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = (order) => del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = (customer) => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = (customer) => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = (customer) => del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = (id) => get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST)

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB)

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = (id) => get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// add CardData Kanban
export const addCardData = (cardData) => post(url.ADD_CARD_DATA, cardData)

// update jobs
export const updateCardData = (card) => put(url.UPDATE_CARD_DATA, card)

// delete Kanban
export const deleteKanban = (kanban) => del(url.DELETE_KANBAN, { headers: { kanban } })

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = (user) => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = (user) => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = (user) => del(url.DELETE_USER, { headers: { user } })

// add jobs
export const addNewJobList = (job) => post(url.ADD_NEW_JOB_LIST, job)

// update jobs
export const updateJobList = (job) => put(url.UPDATE_JOB_LIST, job)

// delete jobs
export const deleteJobList = (job) => del(url.DELETE_JOB_LIST, { headers: { job } })

// Delete Apply Jobs
export const deleteApplyJob = (data) => del(url.DELETE_APPLY_JOB, { headers: { data } })

/** PROJECT */
// add user
export const addNewProject = (project) => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = (project) => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = (project) => del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get maillist
export const getMailsLists = (filter) =>
  post(url.GET_MAILS_LIST, {
    params: filter
  })

//update mail
export const updateMail = (mail) => put(url.UPDATE_MAIL, mail)

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER)

// post messages
export const addMessage = (message) => post(url.ADD_MESSAGE, message)

// delete message
export const deleteMessage = (data) => del(url.DELETE_MESSAGE, { headers: { data } })

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const walletBalanceData = (month) => get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } })

export const getStatisticData = (duration) => get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } })

export const visitorData = (duration) => get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } })

export const topSellingData = (month) => get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = (month) => get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

// Bank
const getBankListAPI = (data) => get(url.GET_BANK_LIST, { params: { ...data } })

const postBankAPI = (data) => post(url.POST_BANK_CREATE, data)

const getBankDetailsAPI = (data) => get(url.GET_BANK_DETAILS, { params: data })

const postCreateBankAPI = (data) => post(url.POST_BANK_CREATE, data)

const updateBankAPI = (data) => put(url.PUT_BANK + data.id, data)

const deleteBankAPI = (data) => del(url.DELETE_BANK + data.id, { data: { type: data.type } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId }
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId }
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText }
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText }
  })
}

// History
const getHistoryListAPI = (data) => post(url.GET_HISTORY_LIST, data, { params: { page: data?.page || 1 } })

const exportHistoryAPI = (data) => post(url.EXPORT_HISTORY, data, { responseType: 'blob' })

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtLogout,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
  putChangePassword,
  getProfileAPI,
  putProfileAPI,
  postImage,
  getShopsAPI,
  getShopDetailAPI,
  getProvinceAPI,
  getDistrictAPI,
  getWardAPI,
  getBanksAPI,
  getZoneAPI,
  resetPasswordAPI,
  lockAccountAPI,
  acceptMemberAPI,
  putRequestAPI,
  putShopDetailAPI,
  getKTVsAPI,
  exportListAPI,
  getUserRankAPI,
  getUserHistoryChangeApi,
  getPortfolioAllAPI,
  getPortfolioListAPI,
  postPortfolioAPI,
  getPortfolioDetailsAPI,
  updatePortfolioAPI,
  deletePortfolioAPI,
  exportPortfolioAPI,
  getProductAllAPI,
  getProductListAPI,
  postProductAPI,
  checkSerialAPI,
  getProductDetailsAPI,
  updateProductAPI,
  deleteProductAPI,
  exportProductAPI,
  getUnitListAPI,
  postUnitAPI,
  getUnitDetailsAPI,
  updateUnitAPI,
  deleteUnitAPI,
  getDepartmentListAPI,
  postCreateUnitAPI,
  getPermissionListAPI,
  postPermissionAPI,
  getPermissionDetailsAPI,
  updatePermissionAPI,
  deletePermissionAPI,
  postCreatePermissionAPI,
  getRoleListAPI,
  getChannelAPI,
  getProductActivationListAPI,
  postProductActivationAPI,
  getProductActivationDetailsAPI,
  updateProductActivationAPI,
  deleteProductActivationAPI,
  patchLockProductActivationAPI,
  exportProductActivationAPI,
  getUserListAPI,
  postUserAPI,
  getUserDetailsAPI,
  updateUserAPI,
  postLockAccountAPI,
  putChangePasswordAccountAPI,
  getRoleListAll,
  getZoneListAll,
  getSubZoneListAll,
  getProvinceListAll,
  postCreateUserAPI,
  checkRoleManagement,
  getProgramListAPI,
  postProgramAPI,
  getProgramDetailsAPI,
  updateProgramAPI,
  deleteProgramAPI,
  patchStatusProgramAPI,
  getRankAllAPI,
  getSubZoneAPI,
  getLocalityListAPI,
  postLocalityAPI,
  getLocalityDetailsAPI,
  postCreateLocalityAPI,
  updateLocalityAPI,
  deleteLocalityAPI,
  getAreaManagementOptionsAPI,
  importFileAPI,
  checkUploadFileAPI,
  exportFileError,
  downloadTemplateAPI,
  getRankListAPI,
  getRankDetailsAPI,
  postCreateRankAPI,
  updateRankAPI,
  deleteRankAPI,
  getRankConfigListAPI,
  getRankConfigDetailsAPI,
  postCreateRankConfigAPI,
  updateRankConfigAPI,
  deleteRankItemConfigAPI,
  deleteRankConfigAPI,
  getPointConfigListAPI,
  updatePointConfigAPI,
  getPointHistoryListAPI,
  getAccumulationListAPI,
  getAccumulationDetailsAPI,
  updateAccumulationAPI,
  deleteAccumulationAPI,
  exportAccumulationAPI,
  getActivationListAPI,
  getActivationDetailsAPI,
  updateActivationAPI,
  deleteActivationAPI,
  exportActivationAPI,
  getModelBySerialAPI,
  getGuestListAPI,
  getGuestDetailsAPI,
  updateGuestAPI,
  deleteGuestAPI,
  exportGuestAPI,
  getContentsAPI,
  getContentDetailAPI,
  putContentDetailAPI,
  postContentAPI,
  deleteContentAPI,
  patchStatusContentAPI,
  getBanerAllAPI,
  getBanerListAPI,
  postBanerAPI,
  getBanerDetailsAPI,
  updateBanerAPI,
  deleteBanerAPI,
  getContentAllAPI,
  getProgramBonusTempListAPI,
  getProductBonusTempListAPI,
  getParallelProgramListAPI,
  getAccountRankListAPI,
  exportByProductAPI,
  getReconcileProgramByAreaAPI,
  getProgramByAreaAndRewardTypeAPI,
  confirmReconcileByProductAPI,
  confirmReconcileByPackageAPI,
  getReportAccountingSearchByProgramingAPI,
  getReportAccountingSearchProgramingByRewardTypeAPI,
  confirmAccountingReconcileByProductAPI,
  confirmAccountingReconcileByPackageAPI,
  getContactListAPI,
  getContactDetailAPI,
  createContactAPI,
  updateContactAPI,
  deleteContactAPI,
  getBankListAPI,
  postBankAPI,
  getBankDetailsAPI,
  postCreateBankAPI,
  updateBankAPI,
  deleteBankAPI,
  getSMSBrandnameListAPI,
  exportSMSBrandnameAPI,
  exportByProgrammingAPI,
  exportProgrammingByAreaAndRewardTypeAPI,
  getHistoryListAPI,
  exportHistoryAPI,
  setPriorityReconcileProductAPI,
  getNotificationsAPI,
  getNotificationDetailAPI,
  postNotificationAPI,
  updateNotificationAPI,
  getReportUserStatisticAPI,
  getUserAccountsAPI,
  cancleNotificationAPI,
  exportReportAccountingProgrammingByRewardTypeAPI,
  exportTechnicianListAPI,
  exportReportUserStatisticAPI,
  getReportAccountRankAPI,
  exportReportAccountRankAPI,
  downloadTemplateReportAPI,
  downloadTemplateReconcileAccountingAPI,
  createBannerAPI,
  resetTechnicianPasswordAPI,
  lockShopAccountAPI,
  lockTechnicianAccountAPI,
  switchTechnicianToUserAPI,
  switchUserToTechnicianAPI,
  getWardListAll,
  getDistrictListAll,
  myLocationManagementAPI,
  getImportHistoryListAPI,
  getImportHistoryDetailAPI,
  getImportHistoryStatusAPI,
  updateProfileAPI,
  checkExportFileAPI,
  getPointConfigByTimeListAPI,
  postPointConfigByTimeAPI,
  getPointConfigByTimeDetailAPI,
  updatePointConfigByTimeAPI,
  deletePointConfigByTimeAPI,
  getRankHistoryListAPI,
  getExportHistoryListAPI,
  postPreLogin,
  getAuditLogAPI,
  getAuditLogDetailAPI,
  getCustomerCareProductListAPI,
  getCustomerCareWarrantyListAPI,
  exportListFetcherAPI,
  exportPointHistoryListFetcherAPI,
  exportAccumulationFetcherAPI,
  exportActivationFetcherAPI,
  exportTechnicianListFetcherAPI,
  exportProductFetcherAPI,
  exportProductActivationFetcherAPI,
  exportByProductFetcherAPI,
  getActivationListReportAPI,
  exportByProgrammingFetcherAPI
}
