import { Pagination } from 'antd'
import { ReactComponent as DefaultEmptySvg } from 'assets/images/base-table-empty.svg'
import PropsTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { useExpanded, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Table } from 'reactstrap'
import React from 'react'

TableSimple.propTypes = {
  columns: PropsTypes.arrayOf(PropsTypes.object).isRequired,
  data: PropsTypes.arrayOf(PropsTypes.object).isRequired,
  pagination: PropsTypes.exact({
    pageIndex: PropsTypes.number.isRequired,
    pageSize: PropsTypes.number.isRequired,
    totalRows: PropsTypes.number.isRequired
  }).isRequired,
  setPagination: PropsTypes.func.isRequired,
  handleSortBy: PropsTypes.func
}

function TableSimple({ columns, data, pagination, setPagination: setPaginate, handleSortBy, isShowPagination = true }) {
  const { pageIndex, pageSize, totalRows } = pagination
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, state, gotoPage } = useTable(
    {
      columns,
      data,
      disabledSortBy: true,
      autoResetSortBy: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: Math.floor(totalRows / pageSize),
      initialState: {
        pageIndex,
        pageSize
      },
      state: {
        pageIndex,
        pageSize
      },
      setPagination: (value) => {
        setPaginate((prev) => ({ ...prev, pageIndex: value }))
      }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  )

  useEffect(() => {
    handleSortBy(state.sortBy)
  }, [state.sortBy])

  const getEmptyData = () => {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center p-4'>
        <DefaultEmptySvg />
        <span className='fs-4 fw-semibold'>Không có dữ liệu</span>
      </div>
    )
  }

  return (
    <div>
      <div className='table-responsive'>
        {/* {page.length > 0 ? ( */}
        <Table {...getTableProps()} sort>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th key={column.id} className={column.isSort ? 'sorting' : ''}>
                      <div
                        className='m-0 d-flex justify-content-between align-items-center'
                        {...column.getSortByToggleProps()}
                        style={{ minHeight: '40px' }}
                      >
                        {column.render('Header')}
                        {!column.hideSort && (
                          <span className='font-size-20'>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className='mdi mdi-menu-down'></i>
                              ) : (
                                <i className='mdi mdi-menu-up'></i>
                              )
                            ) : (
                              <i className='mdi mdi-menu-swap'></i>
                            )}
                          </span>
                        )}
                      </div>
                      {/* <Filter column={column} /> */}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.map((row) => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {/* ) : (
          getEmptyData()
        )} */}
        <div className='d-flex w-100 justify-content-end'>
          {
            isShowPagination && <Pagination
            total={totalRows}
            current={state.pageIndex}
            pageSize={state.pageSize}
            showSizeChanger={false}
            onChange={(value) => {
              gotoPage(value)
            }}
          />
          }
        </div>
      </div>
    </div>
  )
}

export default TableSimple
