import React from 'react'
import { Navigate } from 'react-router-dom'

// Profile
import UserProfile from '../pages/Profile/user-profile'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'

// Dashboard
import {
  ACCUMULATED_MANAGEMENT_CODE,
  ADMIN_ACCOUNT_MANAGEMENT_CODE,
  ANNOUNCE_MANAGEMENT_CODE,
  AREA_MANAGEMENT_CODE,
  BANNER_MANAGEMENT_CODE,
  CATEGORY_MANAGEMENT_CODE,
  CUSTOMER_MANAGEMENT_CODE,
  DEPARTMENT_MANAGEMENT_CODE,
  POINT_CONFIG_MANAGEMENT_CODE,
  POINT_HISTORY_MANAGEMENT_CODE,
  PRODUCT_ACTIVATION_MANAGEMENT_CODE,
  PRODUCT_MANAGEMENT_CODE,
  PROGRAMING_MANAGEMENT_CODE,
  RANK_CONFIG_MANAGEMENT_CODE,
  RANK_MANAGEMENT_CODE,
  ROLE_MANAGEMENT_CODE,
  TECHNICIAN_ACCOUNT_MANAGEMENT_CODE,
  USER_ACCOUNT_MANAGEMENT_CODE,
  BANK_MANAGEMENT_CODE,
  CONTACT_MANAGEMENT_CODE,
  REPORT_SMS_MANAGEMENT_CODE,
  SYSTEM_NOTIFICATION_MANAGEMENT_CODE,
  REPORT_STATISTIC_MANAGEMENT_CODE,
  REPORT_RANK_ACCOUNT_MANAGEMENT_CODE,
  REPORT_BY_PRODUCT_CODE,
  REPORT_RECONCILE_CODE,
  REPORT_RECONCILE_ACCOUNTING_CODE,
  RANK_HISTORY_MANAGEMENT_CODE
} from 'constants/permission-code'
import ManageAccountClass from 'pages/ManageAccountClass/ManageAccountClass'
import ManageClassConfig from 'pages/ManageAccountClass/ManageClassConfig'
import ManageClassStructure from 'pages/ManageAccountClass/ManageClassStructure'
import ManagePointHistory from 'pages/ManageAccountClass/ManagePointHistory'
import AddEditClassStructure from 'pages/ManageAccountClass/components/ManageClassStructure/AddEditClassStructure'
import ManageAccumulation from 'pages/ManageAccumulation'
import ManageActivation from 'pages/ManageActivation'
import ManageBaner from 'pages/ManageBaner'
import ConfigBanner from 'pages/ManageBaner/ConfigBanner'
import ManageContent from 'pages/ManageContent'
import ContentDetail from 'pages/ManageContent/ContentDetail'
import ManageGuest from 'pages/ManageGuest'
import ManageKTV from 'pages/ManageKTV'
import KTVDetail from 'pages/ManageKTV/KTVDetail'
import ManageLocality from 'pages/ManageLocality'
import ManagePermission from 'pages/ManagePermission'
import ManagePortfolio from 'pages/ManagePortfolio'
import ManageProduct from 'pages/ManageProduct'
import ManageProductActivation from 'pages/ManageProductActivation'
import ManageProgram from 'pages/ManageProgram'
import ProgramDetail from 'pages/ManageProgram/ProgramDetail'
import ManageShop from 'pages/ManageShop'
import ShopDetail from 'pages/ManageShop/ShopDetail'
import ManageUnit from 'pages/ManageUnit'
import ManageUser from 'pages/ManageUser'
import UserAddEditDetail from 'pages/ManageUser/UserAddEditDetail'
import ProgramBonusTempList from 'pages/ProgramBonusTemp'
import Spinners from 'components/Common/Spinner'
import Loading from 'components/Common/Loading'
import ReconcileProgramByArea from 'pages/ProgramBonusTemp/ReconcileProgramByArea'
import ProductBonusTempList from 'pages/ReportTemporaryReconciliation/ProductBonusTempList'
import ChangePassword from '../pages/ChangePassword'
import ProgramByAreaAndRewardType from 'pages/ProgramBonusTemp/ProgramByAreaAndRewardType'
import PackageProgramByAreaAndRewardType from 'pages/ProgramBonusTemp/PackageProgramByAreaAndRewardType'
import AddEditProductActivation from 'pages/ManageProductActivation/components/AddEditProductActivation'
import AddEditActivation from 'pages/ManageActivation/components/AddEditActivation'
import DisplayProgramByAreaAndRewardType from 'pages/ProgramBonusTemp/DisplayProgramByAreaAndRewardType'
import ProgramByAreaAndRewardTypeDisplay from 'pages/ProgramBonusTemp/ProgramByAreaAndRewardTypeDisplay'
import PackageProgramByAreaAndRewardTypeDisplay from 'pages/ProgramBonusTemp/PackageProgramByAreaAndRewardTypeDisplay'
import DisplayProgramByAreaAndRewardTypeDisplay from 'pages/ProgramBonusTemp/DisplayProgramByAreaAndRewardTypeDisplay'
import ManageBank from 'pages/ManageBank'
import ProgramByRewardType from 'pages/Reward-verify/ProgramByRewardType'
import PackageProgramByRewardType from 'pages/Reward-verify/PackageProgramByRewardType'
import DisplayProgramByRewardType from 'pages/Reward-verify/DisplayProgramByRewardType'
import ProgramByRewardTypeDisplay from 'pages/Reward-verify/ProgramByRewardTypeDisplay'
import PackageProgramByRewardTypeDisplay from 'pages/Reward-verify/PackageProgramByRewardTypeDisplay'
import DisplayProgramByRewardTypeDisplay from 'pages/Reward-verify/DisplayProgramByRewardTypeDisplay'
import ManageContacts from 'pages/ManageContact/ManageContacts'
import ManageSMSBrandname from 'pages/ManageSMSBrandname/ManageSMSBrandname'
import ManageHistory from 'pages/ManageHistory'
import ManageNotification from 'pages/ManageNotification'
import NotificationDetail from 'pages/ManageNotification/NotificationDetail'
import ReportUserStatistic from 'pages/ReportUserStatistic'
import ReportAccountRank from 'pages/ReportAccountRank'
import ManageImportHistory from 'pages/ManageImportHistory/ManageImportHistory'
import ManageImportHistoryDetail from 'pages/ManageImportHistory/components/ManageImportHistoryDetail'
import ManagePointConfig from 'pages/ManageAccountClass/ManagePointConfig'
import ManageRankHistory from 'pages/ManageRankHistory/ManageRankHistory'
import ManageExportHistory from 'pages/ManageExportHistory/ManageExportHistory'
import ManageAuditLog from 'pages/ManageAuditLog/ManageAuditLog'
import ManageCustomerCare from 'pages/ManageCustomerCare'
import CustomerCare from 'pages/ManageCustomerCare/CustomerCare'
import DetailAccumulationReport from 'pages/DetailAccumulationReport'

const RewardVerify = React.lazy(() => import('pages/Reward-verify'))
const RecordPayment = React.lazy(() => import('pages/RecordPayment'))
const HistoryActivationAccumulation = React.lazy(() => import('pages/HistoryActivationAccumulation'))
// const ManageContact = React.lazy(() => import('pages/ManageContact'))
// import ManageContacts from 'pages/ManageContacts'

const authProtectedRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },

  //Menu profile
  { path: '/change-password', component: <ChangePassword /> },
  { path: '/profile', component: <UserProfile /> },
  { path: '/profile/edit', component: <UserProfile /> },

  // shop
  {
    path: '/manage-shop',
    component: <ManageShop />,
    permissionCode: USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_USER
  },
  {
    path: '/manage-shop/view/:id',
    component: <ShopDetail />,
    permissionCode: USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_USER
  },
  {
    path: '/manage-shop/edit/:id',
    component: <ShopDetail />,
    permissionCode: USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_USER
  },

  //ktv
  {
    path: '/manage-ktv',
    component: <ManageKTV />,
    permissionCode: TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_SEARCH_LIST_USER
  },
  {
    path: '/manage-ktv/view/:id',
    component: <KTVDetail />,
    permissionCode: TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_SEARCH_LIST_USER
  },
  {
    path: '/manage-ktv/edit/:id',
    component: <KTVDetail />,
    permissionCode: TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_SEARCH_LIST_USER
  },

  //portfolio
  {
    path: '/manage-portfolio',
    component: <ManagePortfolio />,
    permissionCode: CATEGORY_MANAGEMENT_CODE.PRODUCT_SEARCH_CATEGORY
  },

  //product
  {
    path: '/manage-product',
    component: <ManageProduct />,
    permissionCode: PRODUCT_MANAGEMENT_CODE.PRODUCT_SEARCH_PRODUCT
  },

  //product activation
  {
    path: '/manage-product-activation',
    component: <ManageProductActivation />,
    permissionCode: PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_SEARCH_PRODUCT_ACTIVATION
  },

  //unit
  {
    path: '/manage-unit',
    component: <ManageUnit />,
    permissionCode: DEPARTMENT_MANAGEMENT_CODE.ADMIN_SEARCH_DEPARTMENT
  },

  //permission
  {
    path: '/manage-permission',
    component: <ManagePermission />,
    permissionCode: ROLE_MANAGEMENT_CODE.ADMIN_SEARCH_ROLE
  },

  //user
  {
    path: '/manage-user',
    component: <ManageUser />,
    permissionCode: ADMIN_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_ADMIN_ACCOUNT
  },
  {
    path: '/manage-user/create',
    component: <UserAddEditDetail />
  },
  {
    path: '/manage-user/view/:id',
    component: <UserAddEditDetail />
  },
  {
    path: '/manage-user/edit/:id',
    component: <UserAddEditDetail />
  },

  //program
  {
    path: '/manage-program',
    component: <ManageProgram />,
    permissionCode: PROGRAMING_MANAGEMENT_CODE.ADMIN_SEARCH_PROGRAMMING
  },
  {
    path: '/manage-program/create',
    component: <ProgramDetail />,
    permissionCode: PROGRAMING_MANAGEMENT_CODE.ADMIN_SEARCH_PROGRAMMING
  },
  {
    path: '/manage-program/view/:id',
    component: <ProgramDetail />,
    permissionCode: PROGRAMING_MANAGEMENT_CODE.ADMIN_SEARCH_PROGRAMMING
  },
  {
    path: '/manage-program/edit/:id',
    component: <ProgramDetail />,
    permissionCode: PROGRAMING_MANAGEMENT_CODE.ADMIN_SEARCH_PROGRAMMING
  },

  //locality
  { path: '/manage-locality', component: <ManageLocality />, permissionCode: AREA_MANAGEMENT_CODE.ADMIN_GET_LIST_AREA },

  //account-class
  {
    path: '/manage-account-class',
    component: <ManageAccountClass />,
    permissionCode: RANK_MANAGEMENT_CODE.RANKS_SEARCH_RANK
  },
  {
    path: '/manage-class-structure',
    component: <ManageClassStructure />,
    permissionCode: RANK_CONFIG_MANAGEMENT_CODE.RANKS_SEARCH_RANK_CONFIG
  },
  {
    path: '/manage-class-structure/create',
    component: <AddEditClassStructure />,
    permissionCode: RANK_CONFIG_MANAGEMENT_CODE.RANKS_SEARCH_RANK_CONFIG
  },
  {
    path: '/manage-class-structure/view/:id',
    component: <AddEditClassStructure />,
    permissionCode: RANK_CONFIG_MANAGEMENT_CODE.RANKS_SEARCH_RANK_CONFIG
  },
  {
    path: '/manage-class-structure/edit/:id',
    component: <AddEditClassStructure />,
    permissionCode: RANK_CONFIG_MANAGEMENT_CODE.RANKS_SEARCH_RANK_CONFIG
  },
  {
    path: '/manage-point-config',
    component: <ManageClassConfig />,
    permissionCode: POINT_CONFIG_MANAGEMENT_CODE.RANK_SEARCH_POINT_CONFIG
  },
  {
    path: '/manage-point-config-by-time',
    component: <ManagePointConfig />
    // permissionCode: POINT_CONFIG_MANAGEMENT_CODE.RANK_SEARCH_POINT_CONFIG
  },
  {
    path: '/manage-point-history',
    component: <ManagePointHistory />
    // permissionCode: RANK_HISTORY_MANAGEMENT_CODE.RANK_HISTORY
  },

  //accumulation
  {
    path: '/manage-accumulation',
    component: <ManageAccumulation />,
    permissionCode: ACCUMULATED_MANAGEMENT_CODE.ADMIN_SEARCH_ACCUMULATED
  },

  //activation
  { path: '/manage-activation', component: <ManageActivation /> },
  { path: '/manage-activation/view/:id', component: <AddEditActivation /> },

  //history
  { path: '/manage-history', component: <ManageHistory /> },

  //guest
  { path: '/manage-guest', component: <ManageGuest />, permissionCode: CUSTOMER_MANAGEMENT_CODE.ADMIN_SEARCH_CUSTOMER },

  // content
  {
    path: '/manage-content',
    component: <ManageContent />,
    permissionCode: ANNOUNCE_MANAGEMENT_CODE.ADMIN_SEARCH_ANNOUNCE
  },
  {
    path: '/manage-content/view/:id',
    component: <ContentDetail />
  },
  {
    path: '/manage-content/edit/:id',
    component: <ContentDetail />
  },
  {
    path: '/manage-content/create',
    component: <ContentDetail />
  },

  //BANER
  { path: '/manage-banner', component: <ManageBaner />, permissionCode: BANNER_MANAGEMENT_CODE.ADMIN_SEARCH_BANNER },
  {
    path: '/manage-banner/config',
    component: <ConfigBanner />,
    permissionCode: BANNER_MANAGEMENT_CODE.ADMIN_SEARCH_BANNER
  },
  // { path: "/manage-baner/edit/:id", component: <AddEditClassStructure /> },
  // BANK
  { path: '/manage-bank', component: <ManageBank />, permissionCode: BANK_MANAGEMENT_CODE.BANK_SEARCH_BANK },

  //report
  {
    path: '/product-bonus-temp-list',
    component: <ProductBonusTempList />,
    permissionCode: REPORT_BY_PRODUCT_CODE.REPORT_SEARCH_BY_PRODUCT
  },
  {
    path: '/program-bonus-temp-list',

    component: <ProgramBonusTempList />,
    permissionCode: REPORT_RECONCILE_CODE.REPORT_SEACH_BY_PROGRAMMING
  },
  { path: '/reconcile-programming-by-area/:id', component: <ReconcileProgramByArea /> },
  { path: '/programming-by-area-and-reward-type/:id', component: <ProgramByAreaAndRewardType /> },
  { path: '/confirm-reconcile-by-product/:id/province/:province_id', component: <ProgramByAreaAndRewardType /> },
  { path: '/package-programming-by-area-and-reward-type/:id', component: <PackageProgramByAreaAndRewardType /> },
  { path: '/display-programming-by-area-and-reward-type/:id', component: <DisplayProgramByAreaAndRewardType /> },
  { path: '/programming-by-area-and-reward-type-view/:id', component: <ProgramByAreaAndRewardTypeDisplay /> },
  {
    path: '/package-programming-by-area-and-reward-type-view/:id',
    component: <PackageProgramByAreaAndRewardTypeDisplay />
  },
  {
    path: '/display-programming-by-area-and-reward-type-view/:id',
    component: <DisplayProgramByAreaAndRewardTypeDisplay />
  },

  // log accountant log kế toán
  {
    path: '/reward-verify',
    component: <RewardVerify />,
    permissionCode: REPORT_RECONCILE_ACCOUNTING_CODE.REPORT_ACCOUNTING_SEARCH_BY_PROGRAMMING
  },
  {
    path: '/reward-verify/record-payment/:id',
    component: <ProgramByRewardType />
  },
  {
    path: '/reward-verify/record-payment-package/:id',
    component: <PackageProgramByRewardType />
  },
  {
    path: '/reward-verify/record-payment-displaypackage/:id',
    component: <DisplayProgramByRewardType />
  },

  {
    path: '/reward-verify/record-payment-view/:id',
    component: <ProgramByRewardTypeDisplay />
  },
  {
    path: '/reward-verify/record-payment-package-view/:id',
    component: <PackageProgramByRewardTypeDisplay />
  },
  {
    path: '/reward-verify/record-payment-displaypackage-view/:id',
    component: <DisplayProgramByRewardTypeDisplay />
  },
  // {
  //   path: '/history-activation-accumulation',
  //   component: <HistoryActivationAccumulation />
  // },
  // {
  //   path: '/manage-contact',
  //   component: <ManageContact />
  // },

  //contacts
  {
    path: '/manage-contacts',
    component: <ManageContacts />
  },

  //sms-brandname
  {
    path: '/manage-sms-brandname',
    component: <ManageSMSBrandname />,
    permissionCode: REPORT_SMS_MANAGEMENT_CODE.REPORT_SMS_STATISTIC_SEARCH
  },

  // notification
  {
    path: '/manage-notification',
    component: <ManageNotification />,
    permissionCode: SYSTEM_NOTIFICATION_MANAGEMENT_CODE.SYSTEM_NOTIFICATION_SEARCH
  },
  {
    path: '/manage-notification/view/:id',
    component: <NotificationDetail />
  },
  {
    path: '/manage-notification/edit/:id',
    component: <NotificationDetail />
  },
  {
    path: '/manage-notification/create',
    component: <NotificationDetail />
  },
  //User Statistic -- Báo cáo thống kê hoạt động
  {
    path: '/user-statistic',
    component: <ReportUserStatistic />,
    permissionCode: REPORT_STATISTIC_MANAGEMENT_CODE.REPORT_STATISTIC_USER_ACTIVE_BY_AREAR
  },
  //Account Rank -- Báo cáo xếp hạng tài khoản
  {
    path: '/account-rank',
    component: <ReportAccountRank />,
    permissionCode: REPORT_RANK_ACCOUNT_MANAGEMENT_CODE.REPORT_ACCOUNT_RANK_TYPE_ACCUMULATION
  },

  //import history
  // content
  {
    path: '/manage-import-history',
    component: <ManageImportHistory />
  },
  {
    path: '/manage-import-history/:id',

    component: <ManageImportHistoryDetail />
  },
  //export history
  {
    path: '/manage-export-history',
    component: <ManageExportHistory />
  },

  //RANK history
  {
    path: '/manage-rank-history',
    component: <ManageRankHistory />
  },

  //auditlog
  {
    path: '/audit-log',
    component: <ManageAuditLog />
    // permissionCode: PRODUCT_MANAGEMENT_CODE.PRODUCT_AUDIT_LOG
  },

  //customer care
  {
    path: '/customer-care',
    component: <CustomerCare />
    // permissionCode: PROGRAMING_MANAGEMENT_CODE.ADMIN_SEARCH_PROGRAMMING
  },
  {
    path: '/detail-accumulation-report',
    component: <DetailAccumulationReport />,
  },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to='/manage-shop' />
  },
  { path: '/logout', component: <Logout /> }
]

const publicRoutes = [{ path: '/login', component: <Login /> }]

export { authProtectedRoutes, publicRoutes }
